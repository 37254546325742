import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { EssAnimationsHelper } from '@ess/shared/utils/helpers';
import { SharedMenuItem } from '@ess/shared/utils/models';

@Component({
  selector: 'ess-shared-ui-components-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, SharedUiIconDirective, MatTooltipModule],
  templateUrl: './shared-ui-components-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [EssAnimationsHelper.slideRight(150)],
})
export class SharedUiComponentsMenuItemComponent {
  @Input() item: SharedMenuItem | null = null;
  @Input() collapsed: boolean | null = false;
}
