import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';

@Component({
  selector: 'ess-shared-ui-components-snippet',
  standalone: true,
  imports: [CommonModule, SharedUiIconDirective],
  templateUrl: './shared-ui-components-snippet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiComponentsSnippetComponent {
  @Input() state: 'alert' | 'info' | 'success' | 'warning' = 'info';
  @Input() appearance: 'base' | 'flat' = 'base';
  @Input() size: 'small' | 'mini' = 'small';
}
