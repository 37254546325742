<div
  #menuHost
  class="shared-tree-dropdown-host"
  [cdkMenuTriggerFor]="menu"
  [matTooltip]="_value[_value.length - 1]"
  [matTooltipClass]="'shared-tooltip'"
  (cdkMenuOpened)="_toggleOpened(true)"
  (cdkMenuClosed)="_toggleOpened(false)"
>
  <span class="line-clamp-1">{{ _value | joinArray : ' → ' }}</span>
  <i essIcon="chevron-down" class="transition-transform" [class.-rotate-180]="_opened"></i>
</div>

<ng-template #menu>
  <div class="shared-tree-dropdown" cdkMenu>
    <mat-tree [dataSource]="_dataSource" [treeControl]="_treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="_nodeSelected(node)">
        <span
          class="pl-3 line-clamp-1"
          [class.font-medium]="_selectedNode === node.id"
          [matTooltip]="node.name"
          [matTooltipClass]="'shared-tooltip'"
        >
          {{ node.name }}
        </span>
        @if (_selectedNode === node.id) {
        <i essIcon="check_circle" class="text-primary"></i>
        }
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: _hasChild" matTreeNodePadding matTreeNodeToggle>
        <i
          class="text-base-tertiary ml-2 transition-transform"
          essIcon="chevron_right"
          essIconSize="xxl"
          [class.rotate-90]="_treeControl.isExpanded(node)"
        ></i>
        <span class="whitespace-nowrap line-clamp-1 flex-shrink-0">
          {{ node.name }}
        </span>
        @if (loadingNode === node.id) {
        <mat-progress-bar mode="indeterminate" class="flex-shrink"></mat-progress-bar>
        }
      </mat-tree-node>
    </mat-tree>
  </div>
</ng-template>
