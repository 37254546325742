import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedUiLoadingDirective } from '@ess/shared/ui/loading';

@Component({
  selector: 'ess-shared-ui-components-data-tile',
  standalone: true,
  template: ` <div
    class="w-full h-14 bg-accent-50 rounded-lg border border-slate-primary-12 flex justify-between px-2 items-center gap-x-3"
  >
    <span class="font-medium text-base-secondary text-xs uppercase shrink grow-0 whitespace-pre-line">{{ title }}</span>
    <div class="shrink-0 grow">
      <span
        *essLoading="loading"
        class="block font-medium text-primary-900 text-base whitespace-pre w-full text-right"
        >{{ value || value === 0 ? value : '-' }}</span
      >
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedUiLoadingDirective],
})
export class SharedUiComponentsDataTileComponent {
  @Input() title = '';
  @Input() value: number | string = '-';
  @Input() loading: boolean | null = null;
}
