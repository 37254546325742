import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { SharedUiButtonComponent, SharedUiIconButtonComponent } from '@ess/shared/ui/button';
import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedAction, SharedActionsMenu, SharedKebabAction, SharedMainAction } from '@ess/shared/utils/models';

import { SharedUiComponentsHelpIconComponent } from '../shared-ui-components-help-icon/shared-ui-components-help-icon.component';

@Component({
  selector: 'ess-shared-ui-components-actions-menu',
  standalone: true,
  imports: [
    CommonModule,
    SharedUiButtonComponent,
    SharedUiIconDirective,
    MatMenuModule,
    SharedUiComponentsHelpIconComponent,
    SharedUiIconButtonComponent,
  ],
  templateUrl: './shared-ui-components-actions-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiComponentsActionsMenuComponent {
  @Input() set menu(actions: SharedActionsMenu) {
    this._kebabActions = [...actions.filter((a: SharedAction) => !a.primary)];
    this._mainAction = actions.find((a: SharedAction) => a.primary) || null;
  }

  protected _kebabActions: SharedKebabAction[] = [];
  protected _mainAction: SharedMainAction | null = null;
}
