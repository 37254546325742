<div
  class="w-full flex rounded border p-2 gap-x-2"
  [ngClass]="{
    border: appearance === 'base',
    'border-buttons-secondary bg-accent-100': appearance === 'base' && state === 'info',
    'border-warn-200 bg-warn-50': appearance === 'base' && state === 'warning',
    'border-warn-600 bg-warn-100': appearance === 'base' && state === 'alert',
    'border-success bg-success-50': appearance === 'base' && state === 'success',
    'bg-mat-background': appearance === 'flat'
  }"
>
  @switch (appearance) { @case ('flat') {
  <i
    [essIcon]="state === 'info' ? 'info_filled' : state === 'success' ? 'check_circle_filled' : 'warning_filled'"
    [essIconSize]="size === 'mini' ? 'sm' : 'md'"
    [ngClass]="{
      'text-base-note-100': state === 'info',
      'text-warn-400': state === 'warning',
      'text-warn-600': state === 'alert',
      'text-success': state === 'success'
    }"
  ></i>
  } @default {
  <i
    [essIcon]="state === 'info' ? 'info' : state === 'success' ? 'check_circle' : 'warning_amber'"
    [essIconSize]="size === 'mini' ? 'sm' : 'md'"
    [ngClass]="{
      'text-base-note-200': state === 'info',
      'text-warn-400': state === 'warning',
      'text-warn-600': state === 'alert',
      'text-success': state === 'success'
    }"
  ></i>
  } }

  <div class="text-left" [ngClass]="{ 'text-sm': size === 'small', 'text-label-xs': size === 'mini' }">
    <ng-content></ng-content>
  </div>
</div>
