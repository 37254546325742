@if (_mainAction || _kebabActions.length) {
<div class="flex items-center gap-x-2.5">
  @if (_mainAction) {
  <ess-shared-ui-button [disabled]="!!_mainAction.disabled" (click)="_mainAction.action()"
    >{{ _mainAction.label }}
  </ess-shared-ui-button>
  } @if (_kebabActions.length) {
  <ess-shared-ui-icon-button
    #trigger="matMenuTrigger"
    [class.always-show]="trigger.menuOpen"
    [icon]="'more_vert'"
    [size]="'lg'"
    [matMenuTriggerFor]="menu"
  />
  }
</div>
}

<mat-menu #menu="matMenu" class="shared-menu shared-menu-actions">
  @for (action of _kebabActions; track action) {
  <div mat-menu-item [disabled]="action.disabled" (click)="!action.disabled && action.action()">
    <div class="flex items-center gap-x-2 {{ action.class }}">
      @if (action.icon; as icon) {
      <i [essIcon]="icon" [essIconSize]="'xl'" [ngClass]="{ 'text-base-tertiary': !action.class }"></i>
      }
      <span>{{ action.label }}</span>
      <ess-shared-ui-components-help-icon class="ml-auto" [message]="action.description" [tooltipPosition]="'below'" />
    </div>
  </div>
  }
</mat-menu>
