import { CdkMenu, CdkMenuTrigger } from '@angular/cdk/menu';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedTreeElement, SharedTreeNode } from '@ess/shared/utils/models';
import { JoinArrayPipe } from '@ess/shared/utils/pipes';

import { SharedUiComponentsTreeDropdownDataSource } from './shared-ui-components-tree-dropdown.data-source';

@UntilDestroy()
@Component({
  selector: 'ess-shared-ui-components-tree-dropdown',
  standalone: true,
  imports: [
    CdkMenuTrigger,
    MatTreeModule,
    MatProgressBarModule,
    CdkMenu,
    JoinArrayPipe,
    MatTooltipModule,
    SharedUiIconDirective,
  ],
  templateUrl: './shared-ui-components-tree-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiComponentsTreeDropdownComponent implements OnInit {
  @ViewChild('menuHost') menuHost!: ElementRef<HTMLDivElement>;
  @Input() loadingNode: string | null = null;

  @Output() nodeExpanded: EventEmitter<string> = new EventEmitter<string>();
  @Output() nodeSelected: EventEmitter<string> = new EventEmitter<string>();

  @Input() set topLevelNodes(nodes: SharedTreeElement[] | null) {
    this._dataSource.setData(nodes ?? []);
  }

  @Input() set childrenMap(map: Map<string, SharedTreeElement[]> | null) {
    this._dataSource.updateChildrenMap(map ?? new Map());
    this._value = this._selectedNode ? this._dataSource.getNodePath(this._selectedNode) : [];
  }

  @Input() set selectedNode(selected: string | null) {
    this._selectedNode = selected;
    this._value = this._selectedNode ? this._dataSource.getNodePath(this._selectedNode, true) : [];
  }

  protected _value: string[] = [];
  protected _selectedNode: string | null = null;
  protected _opened = false;

  protected _treeControl: FlatTreeControl<SharedTreeNode> = new FlatTreeControl<SharedTreeNode>(
    (node) => node.level,
    (node) => node.expandable,
  );
  protected _dataSource: SharedUiComponentsTreeDropdownDataSource = new SharedUiComponentsTreeDropdownDataSource(
    this._treeControl,
  );

  ngOnInit(): void {
    this.__listenNodeExpanded();
  }

  protected _hasChild: (_: number, node: SharedTreeNode) => boolean = (_: number, node: SharedTreeNode) =>
    node.expandable;

  protected _toggleOpened(opened: boolean): void {
    this._opened = opened;
  }

  protected _nodeSelected(node: SharedTreeNode): void {
    this.menuHost.nativeElement.click();
    this.nodeSelected.emit(node.id);
  }

  private __listenNodeExpanded(): void {
    this._dataSource.nodeExpanded
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((slug: string) => this.nodeExpanded.emit(slug));
  }
}
