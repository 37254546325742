import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, numberAttribute, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { SharedUiLoadingDirective } from '@ess/shared/ui/loading';

function toNumberOrNull(value: string | number | null): number | null {
  return value === null ? null : numberAttribute(value);
}

@Component({
  selector: 'ess-shared-ui-components-card-toggle',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule, SharedUiLoadingDirective],
  templateUrl: './shared-ui-components-card-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiComponentsCardToggleComponent {
  @Input() label = '';
  @Input({ transform: toNumberOrNull }) valueLabel: number | null = null;
  @Input() selected = false;
  @Input() loading: boolean | null = null;
  @Input() selectable = true;
  @Input() details = false;

  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle(): void {
    if (this.selectable) {
      this.selected = !this.selected;
      this.selectedChange.emit(this.selected);
    }
  }
}
