import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { SharedIconSize, SharedIconType } from '@ess/shared/utils/models';

@Component({
  selector: 'ess-shared-ui-components-help-icon',
  standalone: true,
  imports: [MatTooltipModule, SharedUiIconDirective],
  template: `
    @if (message) {
    <i
      class="{{ textClass }} cursor-default"
      [essIcon]="icon"
      [essIconSize]="size"
      [matTooltip]="message"
      [matTooltipClass]="'shared-tooltip shared-tooltip-lg'"
      [matTooltipPosition]="tooltipPosition"
    ></i>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ':host { @apply inline-flex }',
})
export class SharedUiComponentsHelpIconComponent {
  @Input() message: string | undefined | null = null;
  @Input() textClass = 'text-base-note-100';
  @Input() icon: string | SharedIconType = 'question-mark';
  @Input() size: SharedIconSize = 'md';
  @Input() tooltipPosition: 'above' | 'below' = 'above';
}
