<div class="flex gap-x-2 items-center">
  <span
    class="text-base-note-100 truncate hover:underline decoration-base-tertiary cursor-pointer"
    [matTooltip]="text"
    [matTooltipClass]="'shared-tooltip'"
  >
    {{ text }}
  </span>
  <i
    essIcon="content_copy"
    class="text-primary cursor-pointer"
    [matTooltip]="'Copy link to clipboard'"
    [matTooltipClass]="'shared-tooltip'"
    (click)="_copyToClipboard($event)"
  ></i>
</div>
