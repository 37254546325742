<mat-radio-group [ngModel]="selected">
  <div
    class="shared-radio-card-group border border-solid border-slate-primary-12 rounded-lg bg-accent-50 inline-flex w-full min-h-full p-3 items-center relative"
    [ngClass]="{ 'hover:border-primary cursor-pointer': selectable }"
    (click)="toggle()"
  >
    <div class="flex flex-col justify-start items-start gap-y-1.5">
      <div class="flex flex-col justify-start items-stretch gap-y-1 self-stretch" [class.pr-7]="selectable">
        @if (label) {
        <span class="text-xs leading-3 uppercase text-base-note-200">{{ label }}</span>
        }
        <span *essLoading="loading; maxWidth: '6rem'" class="text-title">{{ valueLabel ?? '-' }}</span>
      </div>
      <ng-content select="[chip]"></ng-content>
    </div>

    <ng-content select="[details]"></ng-content>
    @if (selectable) {
    <div>
      <mat-radio-button
        class="shared-radio-card absolute top-3 right-3"
        color="primary"
        [value]="true"
        [disableRipple]="true"
      ></mat-radio-button>
    </div>
    }
  </div>
</mat-radio-group>
