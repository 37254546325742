import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';

export interface EssSnackBarConfig {
  type: 'success' | 'error' | 'info';
  text: string;
}

const EssSnackBarIconDictionary = {
  success: 'done',
  error: 'sad-face',
  info: 'info',
};

@Component({
  selector: 'ess-shared-ui-components-snackbar',
  standalone: true,
  imports: [SharedUiIconDirective],
  templateUrl: './shared-ui-components-snackbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiComponentsSnackbarComponent {
  protected readonly EssSnackBarIconDictionary = EssSnackBarIconDictionary;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) protected _config: EssSnackBarConfig,
    protected _snackbarRef: MatSnackBarRef<SharedUiComponentsSnackbarComponent>,
  ) {}
}
