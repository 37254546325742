import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { EventEmitter } from '@angular/core';
import { MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, filter, map, merge, Observable, Subscription } from 'rxjs';

import { SharedTreeElement, SharedTreeNode } from '@ess/shared/utils/models';

export class SharedUiComponentsTreeDropdownDataSource implements DataSource<SharedTreeNode> {
  readonly nodeExpanded: EventEmitter<string> = new EventEmitter<string>();
  private __data: SharedTreeElement[] = [];
  private readonly __displayData$: BehaviorSubject<SharedTreeNode[]> = new BehaviorSubject<SharedTreeNode[]>([]);
  private readonly __treeFlattener: MatTreeFlattener<SharedTreeElement, SharedTreeNode>;
  private __sub: Subscription = new Subscription();

  constructor(private readonly __treeControl: FlatTreeControl<SharedTreeNode>) {
    this.__treeFlattener = new MatTreeFlattener(
      this.__getTransformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children,
    );
  }

  setData(elements: SharedTreeElement[]): void {
    this.__data = elements;
    const nodes: SharedTreeNode[] = this.__treeFlattener.flattenNodes(elements);
    this.__treeControl.dataNodes = nodes;
    this.__displayData$.next(nodes);
  }

  updateChildrenMap(map: Map<string, SharedTreeElement[]>): void {
    this.__data = this.__data.map((element) => ({ ...element, children: map.get(element.id) ?? [] }));
  }

  connect(collectionViewer: CollectionViewer): Observable<SharedTreeNode[]> {
    this.__sub = this.__treeControl.expansionModel.changed
      .pipe(filter((change: SelectionChange<SharedTreeNode>) => change.added.concat(change.removed).length > 0))
      .subscribe(this.__handleExpansionChange.bind(this));

    return merge(collectionViewer.viewChange, this.__displayData$).pipe(map(() => this.__displayData$.value));
  }

  disconnect(): void {
    this.__sub.unsubscribe();
  }

  getNodePath(id: string, expand = false): string[] {
    const path: string[] = [];
    const nodes = this.__treeFlattener.flattenNodes(this.__data);
    const nodeIndex: number = nodes.findIndex((e) => e.id === id);
    if (nodeIndex >= 0) {
      let node = nodes[nodeIndex];
      let level = node.level;
      path.unshift(node.path);
      for (let i = nodeIndex - 1; i >= 0; i--) {
        node = nodes[i];
        if (node.level < level) {
          level = node.level;
          path.unshift(node.path);
          const treeNode = this.__treeControl.dataNodes.find((n) => n.id === node.id);
          if (expand && treeNode && !this.__treeControl.isExpanded(treeNode)) {
            this.__treeControl.expand(treeNode);
          }
        }
      }
      this.__updateDisplayData();
    }
    return path;
  }

  private readonly __getTransformer = (node: SharedTreeElement, level: number): SharedTreeNode => ({
    expandable: level === 0,
    name: node.label,
    level: level,
    id: node.id,
    path: node.path,
  });

  private __updateDisplayData(): void {
    const displayData: SharedTreeNode[] = [];
    this.__treeControl.dataNodes.forEach((node) => {
      const nodeElement = this.__data.find((e) => e.id === node.id);
      if (nodeElement) {
        displayData.push(node);
        if (this.__treeControl.isExpanded(node)) {
          const flatTree = this.__treeFlattener.flattenNodes([nodeElement]).filter((e) => e.level > 0);
          displayData.push(...flatTree);
        }
      }
    });
    this.__displayData$.next(displayData);
  }

  private __handleExpansionChange(change: SelectionChange<SharedTreeNode>): void {
    let update: boolean = change.removed.length > 0;
    if (change.added.length) {
      const expanded: string = change.added[0].id;
      const node: SharedTreeElement | undefined = this.__data.find((item) => item.id === expanded);
      node?.children.length ? (update = true) : this.nodeExpanded.emit(expanded);
    }
    if (update) {
      this.__updateDisplayData();
    }
  }
}
