import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';

// TODO: to be removed and replaced with UserInteractionService method (won't be needed in new LPO)
@Component({
  selector: 'ess-shared-ui-components-clipboard-text',
  standalone: true,
  imports: [MatTooltipModule, SharedUiIconDirective],
  templateUrl: './shared-ui-components-clipboard-text.component.html',
})
export class SharedUiComponentsClipboardTextComponent {
  @Input() text = '';
  @Output() copied = new EventEmitter();

  constructor(private readonly __clipboard: Clipboard) {}

  protected _copyToClipboard(event: Event): void {
    event.stopPropagation();
    this.__clipboard.copy(this.text);
    this.copied.emit();
  }
}
