@if (item) {
<a
  routerLinkActive="text-primary active"
  class="group flex justify-start items-start gap-x-2 text-base-note-100 hover:text-primary-800 select-none w-full whitespace-pre"
  [ngClass]="{
    'text-base-disabled hover:text-base-disabled hover:cursor-default': !item.link,
  }"
  [routerLink]="item.link"
>
  @if (item.icon; as icon) {
  <i
    [essIcon]="icon"
    [essIconSize]="'xxl'"
    [matTooltip]="item.label ?? ''"
    [matTooltipDisabled]="!collapsed"
    [matTooltipPosition]="'after'"
    [matTooltipClass]="'shared-tooltip'"
  ></i>
  } @if (!collapsed) {
  <span class="text-lg group-[.active]:text-title" @slideRight>{{ item.label }}</span>
  }
</a>
}
